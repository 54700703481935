<template>
    <div class="college">
        <div class="bigImg bgimg" >
            <!-- <img src="../../assets/img/skin/bigimg1.jpg" alt=""> -->
        </div>
<div class="main fixed"> <div class="left wow fadeInLeft animated" style="visibility: visible; animation-name: fadeInLeft;">
  <div class="sideMenu">
    <div class="sideTit"> <a>
      <h3>商学院 </h3>
      <p>jinrongfuwu </p>
      </a> </div>
    <div class="mobileTit"><a type="button">商学院</a></div>
    <ul class="sideMenuNav">
          <li :class="item.on?'on':''" v-for="(item,index) in menuList" :key="index"  @click="cutmenu(index,item.id)"><i>&nbsp;</i><a href="javascript:;">{{item.name}}</a></li>
      </ul>
  </div>
  <app-config></app-config>
</div>
 
    <!--left end-->
    
    <div class="right wow fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">
          <div class="path">
        <h3>课题研究</h3>
        <div class="pathdiv"> <a >主页</a> &gt; <span>&gt;</span><a >商学院</a> &gt; <span>&gt;</span><a  class="on">课题研究</a> &gt; </div>
      </div>
          <ul class="downLoad newsList" v-if="!contentFlag">
            <li v-for="(item,index) in article.data" :key="index" @click="getArticleDetails(item.id)">
              <a ><span>{{item.created_at}}</span>
              <div>{{item.title}}</div>
              </a>
              </li>
          </ul>
          <div class="rightBox" v-if="contentFlag">
          <el-button type="text" icon="el-icon-back" @click="contentFlag=false">返回列表</el-button>
            <div class="downLoadTit">
              <h1>{{content.title}}</h1>
              <div class="source">时间：{{content.created_at}}</div>
            </div>
        <div class="content fixed">
              <div>
                
                  <div class="content">
                      <div v-html="content.content">
                          
                      </div>
                    </div>
                </div>
            </div>
      </div>
          <!-- <div class="paged"> <span class="current disabled">上一页</span>
<span class="current disabled">1</span>
<a href="list_22_2.html">2</a>
<a class="p1" href="list_22_2.html">下一页</a>
 </div> -->
        </div>
    <!--right end--> 
    
  </div>
    </div>
</template>

<script>
import AppConfig from "@/components/ahshconfig"

    export default {
       components:{
            AppConfig
        },
        data() {
            return {
                menuList:'',
                article:'',
                id:'',
                content:'',
                contentFlag:false,
            }
        },
        created(){
          
          this.id = this.$route.query.id
          this.getmenuList(this.id)
        },
        methods:{
          getmenuList(id){
            this.$http.get('/api/ahsh/children_menu/'+id).then(res=>{
              let {data,code} = res.data
              if (code == 0) {
                this.menuList = data
                console.log(this.menuList)
                this.getArticle(data[0].id)
              }
            })
          },
          getArticle(id){
              let ajax = {
                  menu_id:id,
                  page:1,
                  page_size:10
              }
              this.$http.post('/api/ahsh/article_list',ajax).then(res=>{
              let {data,code} = res.data
              if (code == 0) {
                this.article = data.data
                console.log(this.article)
              }
            })
          },
          getArticleDetails(id){
            this.$http.get('/api/ahsh/article/'+id).then(res=>{
              let {data,code} = res.data
              if (code == 0) {
                this.content = data
                this.contentFlag = true
                console.log(data)
              }
            })
          },
          cutmenu(i,id){
            this.clearStyle()
            this.getArticle(id)
            this.contentFlag = false
            this.menuList[i].on = true
          },
          clearStyle(){
            let data = this.menuList
            for (let index = 0; index < data.length; index++) {
                data[index].on = false
            }
          },
        },
    }
</script>

<style lang="less" scoped>
    .college{
        .bgimg{
            background-image:url('../../assets/img/skin/bigimg4.jpg');
        }
    }
</style>